<template>
  <router-link
    v-bind:to="{ name: 'aboutyou' }"
    class="levelProgressToast"
  >
    <div class="levelProgressToast__title">
      {{ $t('levelProgressToast.title') }}
    </div>

    <div class="levelProgressToast__body">
      {{ $t('levelProgressToast.body') }}
    </div>

    <div class="levelProgressToast__progressbar">
      <p class="levelProgressToast__levelNumber">
        {{ member.fitlevel.value }}
      </p>

      <Progressbar
        v-bind:percentage="progressPercentage"
        color="#ffb92b"
        background-color="#fff"
        class="levelProgressToast__progressbarComponent"
      />

      <p class="levelProgressToast__levelNumber">
        {{ member.fitlevel.value + 1 }}
      </p>
    </div>
  </router-link>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { mapState } from 'vuex';
import Progressbar from '@/components/Progressbar';
import { ModalBus } from '@/eventBus';

const LevelUpModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/LevelUpModal' /* webpackChunkName: "levelUpModal" */),
}));

export default {
  components: {
    Progressbar,
  },

  computed: {
    ...mapState('member', ['member']),

    progressPercentage() {
      const { xp, xp_next } = this.member.fitlevel;

      return Math.min((100 / xp_next) * xp, 100);
    },

    hasReachedNewLevel() {
      const { xp, xp_next } = this.member.fitlevel;

      return xp >= xp_next;
    },
  },

  mounted() {
    setTimeout(() => {
      if (this.hasReachedNewLevel) {
        ModalBus.emit('open', {
          component: LevelUpModal,
          modifiers: 'levelUp',
        });
      }

      this.$emit('onClose');
    }, 3000);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.levelProgressToast {
  display: block;
  cursor: pointer;
}

.levelProgressToast__title {
  @include label--bold;
  margin: 0 0 rem(3px) 0;

  @include desktop {
    @include small--bold;
  }
}

.levelProgressToast__body {
  @include tiny;
  margin: 0 0 rem(10px) 0;

  @include desktop {
    @include label;
  }
}

.levelProgressToast__progressbar {
  display: flex;
  align-items: center;
  width: 100%;
}

.levelProgressToast__levelNumber {
  @include note--bold;
  color: $color-yellow--dark;

  @include desktop {
    @include small--bold;
  }
}

.levelProgressToast__progressbarComponent {
  margin: 0 12px;
  width: 100%;
}
</style>
